<template>
  <merchant-discounts/>
</template>

<script>
import MerchantDiscounts from  '../admin/merchant-view/MerchantDiscounts.vue'
export default {
  components: {
    MerchantDiscounts
  }
}
</script>